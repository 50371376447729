import { ButtonAsLink } from '../../Button'

import style from './SingupPrompt.styles'

const SignupPrompt: React.FC = () => (
  <ButtonAsLink to="/signup" css={style} secondary>
    Sign up
  </ButtonAsLink>
)

export default SignupPrompt
